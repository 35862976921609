import React from 'react';
import { Link } from 'react-router-dom';
import './mainNavbar.scss';

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from 'reactstrap';

import Scroll from '../Helpers/Scroll';

import logoImg from './../../assets/img/logo_paulo_lourenco_cores.png';

function MainNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState('navbar-transparent');
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const { socialLinks } = props;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor('bg-info-light');
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor('navbar-transparent');
      }
    };
    window.addEventListener('scroll', updateNavbarColor);
    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });

  const toggleNavBar = () => {
    document.documentElement.classList.toggle('nav-open');
    setCollapseOpen(!collapseOpen);
  };
  return (
    <>
      {collapseOpen ? (
        <div
          id='bodyClick'
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={'fixed-top ' + navbarColor} color='info' expand='lg'>
        <Container>
          {/* <UncontrolledDropdown className='button-dropdown'>
            <DropdownToggle
              caret
              data-toggle='dropdown'
              href='#pablo'
              id='navbarDropdown'
              tag='a'
              onClick={(e) => e.preventDefault()}
            >
              <span className='button-bar'></span>
              <span className='button-bar'></span>
              <span className='button-bar'></span>
            </DropdownToggle>
            <DropdownMenu aria-labelledby='navbarDropdown'>
              <DropdownItem header tag='a'>
                Dropdown header
              </DropdownItem>
              <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                Action
              </DropdownItem>
              <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                Another action
              </DropdownItem>
              <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                Something else here
              </DropdownItem>
              <DropdownItem divider></DropdownItem>
              <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                Separated link
              </DropdownItem>
              <DropdownItem divider></DropdownItem>
              <DropdownItem href='#pablo' onClick={(e) => e.preventDefault()}>
                One more separated link
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
          <div className='navbar-translate'>
            <Scroll href='/' type='id' element='top'>
              <NavbarBrand href='/' id='navbar-brand'>
                <div className='logo-wrapper'>
                  <img src={logoImg}></img>
                </div>
              </NavbarBrand>
            </Scroll>

            <UncontrolledTooltip target='#navbar-brand'>
              PLTELECOM - SOLUÇÕES DE SISTEMAS DE SEGURANÇA
            </UncontrolledTooltip>
            <button
              className='navbar-toggler navbar-toggler'
              onClick={() => {
                toggleNavBar();
              }}
              aria-expanded={collapseOpen}
              type='button'
            >
              <span className='navbar-toggler-bar top-bar'></span>
              <span className='navbar-toggler-bar middle-bar'></span>
              <span className='navbar-toggler-bar bottom-bar'></span>
            </button>
          </div>
          <Collapse
            className='justify-content-end'
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <Scroll
                  href='/'
                  onClick={() => {
                    if (collapseOpen) {
                      document.documentElement.classList.toggle('nav-open');
                      setCollapseOpen(!collapseOpen);
                    }
                  }}
                  type='id'
                  element='aboutUs'
                >
                  <NavLink key={'Sobre' + new Date().getTime()}>
                    <i class='fas fa-info-circle'></i> Sobre
                  </NavLink>
                </Scroll>
              </NavItem>
              <NavItem>
                {/* <NavLink to='/index' tag={Link}>
                  FCS e Valores
                </NavLink> */}
                <Scroll
                  href='/'
                  onClick={() => {
                    if (collapseOpen) {
                      document.documentElement.classList.toggle('nav-open');
                      setCollapseOpen(!collapseOpen);
                    }
                  }}
                  type='id'
                  element='Clients'
                >
                  <NavLink key={'FCS e Valores' + new Date().getTime()}>
                    <i class='fas fa-medal'></i> Clientes
                  </NavLink>
                </Scroll>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color='default'
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i className='now-ui-icons design_app mr-1'></i>
                  <p>Soluções</p>
                </DropdownToggle>
                <DropdownMenu>
                  {/* <DropdownItem header tag='span'>
                    Os nossos produtos
                  </DropdownItem> */}
                  <Scroll
                    href='/'
                    onClick={() => {
                      if (collapseOpen) {
                        document.documentElement.classList.toggle('nav-open');
                        setCollapseOpen(!collapseOpen);
                      }
                    }}
                    type='id'
                    element='featuredProducts'
                  >
                    <DropdownItem style={{ fontWeight: 'bold' }}>
                      PRODUTOS EM DESTAQUE
                    </DropdownItem>
                  </Scroll>
                  {/* <DropdownItem divider></DropdownItem> */}
                  <DropdownItem href='/cctv'>CCTV</DropdownItem>
                  <DropdownItem href='/eas'>EAS</DropdownItem>
                  <DropdownItem href='/sadi'>SADI</DropdownItem>
                  <DropdownItem href='/intrusao'>INTRUSÃO</DropdownItem>
                  <DropdownItem href='/controlo-acessos'>
                    CONTROLO ACESSOS
                  </DropdownItem>
                  <DropdownItem href='/detecao-co'>DETEÇÃO CO</DropdownItem>
                  <DropdownItem href='/som-evac'>SOM&EVAC</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <Scroll
                  href='/'
                  onClick={() => {
                    if (collapseOpen) {
                      document.documentElement.classList.toggle('nav-open');
                      setCollapseOpen(!collapseOpen);
                    }
                  }}
                  type='id'
                  element='news'
                >
                  <NavLink key={'Noticias' + new Date().getTime()}>
                    <i class='fas fa-newspaper'></i> Notícias
                  </NavLink>
                </Scroll>
              </NavItem>
              <NavItem key={'Contactar' + new Date().getTime()}>
                <Scroll
                  href='/'
                  onClick={() => {
                    if (collapseOpen) {
                      document.documentElement.classList.toggle('nav-open');
                      setCollapseOpen(!collapseOpen);
                    }
                  }}
                  type='id'
                  element='contact'
                >
                  <NavLink>
                    <i className='fas fa-phone'></i> Contactar
                  </NavLink>
                </Scroll>
              </NavItem>
              <NavItem>
                <NavLink
                  href={socialLinks.facebook.url}
                  target='_blank'
                  id='facebook-tooltip'
                  key={new Date().getTime()}
                >
                  <p className='icon-social'>
                    <i className='fab fa-facebook-square'></i>{' '}
                    <span className='d-lg-none d-xl-none'>Facebook</span>
                  </p>
                </NavLink>
                <UncontrolledTooltip target='#facebook-tooltip'>
                  Like us on Facebook
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href={socialLinks.linkedin.url}
                  target='_blank'
                  id='linkedin-tooltip'
                  key={new Date().getTime() + 1}
                >
                  <p className='icon-social'>
                    <i className='fab fa-linkedin'></i>{' '}
                    <span className='d-lg-none d-xl-none'>Linkedin</span>
                  </p>
                </NavLink>
                <UncontrolledTooltip target='#linkedin-tooltip'>
                  Follow us on Linkedin
                </UncontrolledTooltip>
              </NavItem>

              {/* <NavItem>
                <NavLink
                  href={socialLinks.facebook.url}
                  target='_blank'
                  id='instagram-tooltip'
                >
                  <i className='fab fa-instagram'></i>
                  <p className='d-lg-none d-xl-none'>Instagram</p>
                </NavLink>
                <UncontrolledTooltip target='#instagram-tooltip'>
                  Follow us on Instagram
                </UncontrolledTooltip>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default MainNavbar;
