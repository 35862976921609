import React from 'react';
import loadable from '@loadable/component';
// reactstrap components
import { Container } from 'reactstrap';
import './landing-page-header.scss';
import imageTopPme from '../../../assets/img/top-pme-2023.png'

// core components
const VideoHeader = loadable(() => import('./VideoHeader'));
const ImageHeader = loadable(() => import('./ImageHeader'));

function LandingPageHeader(props) {
  let pageHeader = React.createRef();

  const { title, image, subtitle } = props;

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        if (pageHeader.current) {
          pageHeader.current.style.transform =
            'translate3d(0,' + windowScrollTop + 'px,0)';
        }
      };
      window.addEventListener('scroll', updateScroll);
      return function cleanup() {
        window.removeEventListener('scroll', updateScroll);
      };
    }
  });
  return (
    <>
      <div className='page-header page-header-small'>
        {/* <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg6.jpg") + ")",
          }}
          ref={pageHeader}
        ></div> */}
        {image ? (
          <ImageHeader pageHeader={pageHeader} image={image} />
        ) : (
          <VideoHeader pageHeader={pageHeader} />
        )}
        <div className='content-center'>
          <Container>
            <div className='wrapper-title--hero'>
              <h1 className='title effect-shine'>{title}</h1>
              <h2 className='effect-shine subtitle'>{subtitle}</h2>
            </div>
            <div className='text-center img-toppme--wrapper'>
              <img
              loading={'lazy'}
              src={imageTopPme}
              className='img-toppme'
              alt={'seglink top pme 2023 empresa portuguesa seguranca'}
            ></img>
              {/* <Button
                className='btn-icon btn-round'
                color='info'
                href={socialLinks.facebook.link}
                target='_blank'
                onClick={(e) => window.open(socialLinks.facebook.url, '_blank')}
              >
                <i className='fab fa-facebook-square'></i>
              </Button>
              <Button
                className='btn-icon btn-round'
                color='info'
                href={socialLinks.linkedin.link}
                target='_blank'
                onClick={(e) => window.open(socialLinks.linkedin.url, '_blank')}
              >
                <i className='fab fa-linkedin'></i>
              </Button>
              <Button
                className='btn-icon btn-round'
                color='info'
                href={socialLinks.facebook.link}
                target='_blank'
                onClick={(e) => window.open(socialLinks.facebook.url, '_blank')}
              >
                <i className='fab fa-google-plus'></i>
              </Button> */}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
