/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Container, Button } from 'reactstrap';

import rgpdPDF from '../../assets/files/rgpd.jpg';
import qualityPDF from '../../assets/files/quality.pdf';
import sgsPDF from '../../assets/files/SGS_certidao.pdf';
import ANEPC from '../../assets/files/ANEPC.pdf';
import Registo_PSP from '../../assets/files/Registo_Previo_PSP.pdf';
import Politica_Ambiental from '../../assets/files/Politica_Ambiental.pdf';
// core components

function DefaultFooter(props) {
  const { phones, addresses } = props;
  console.log('props', props);
  return (
    <>
      <footer className='footer footer-default'>
        <Container>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <nav style={{ width: '100%' }}>
              <ul
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                {/* <li>
                <a
                  href="https://www.creative-tim.com?ref=nukr-default-footer"
                  target="_blank"
                >
                  Creative Tim
                </a>
              </li> */}
                {addresses.map((address) => (
                  <li key={Math.random().toString(36).substr(2, 9).toString()}>
                    <a
                      href={address.link}
                      target='_blank'
                      style={{ 'text-transform': 'none', display: 'block' }}
                    >
                      <i className='fas fa-home'></i> {address.label}
                    </a>
                  </li>
                ))}
              </ul>
              <ul
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
                className="mb-3"
              >
                {phones.map((phone) => (
                  <li key={Math.random().toString(36).substr(2, 9).toString()}>
                    <a href={'tel:' + phone.replace(/ /g, "")} target='_blank'>
                      <i className='fas fa-phone'></i> {phone}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            {/* <div className='social-line'>
            <Button
              className='btn-neutral btn-icon btn-round'
              color='facebook'
              href='#pablo'
              onClick={(e) => e.preventDefault()}
            >
              <i className='fab fa-facebook-square'></i>
            </Button>
            <Button
              className='btn-neutral btn-icon btn-round'
              color='twitter'
              href='#pablo'
              onClick={(e) => e.preventDefault()}
              size='lg'
            >
              <i className='fab fa-twitter'></i>
            </Button>
            <Button
              className='btn-neutral btn-icon btn-round'
              color='google'
              href='#pablo'
              onClick={(e) => e.preventDefault()}
            >
              <i className='fab fa-google-plus'></i>
            </Button>
          </div> */}
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                margin: 'auto'
              }}
            >
              <div style={{ margin: '10px' }}>
                <a href={sgsPDF} target='_blank'>
                  <i>Certificação SGS</i>
                </a>
              </div>
              <div style={{ margin: '10px' }}>
                <a href={ANEPC} target='_blank'>
                  <i>Certificação ANEPC</i>
                </a>
              </div>
              <div style={{ margin: '10px' }}>
                <a href={Registo_PSP} target='_blank'>
                  <i>Certificação PSP</i>
                </a>
              </div>
              <div style={{ margin: '10px' }}>
                <a href={qualityPDF} target='_blank'>
                  <i>Política de qualidade</i>
                </a>
              </div>
              <div style={{ margin: '10px' }}>
                <a href={Politica_Ambiental} target='_blank'>
                  <i>Política de ambiental</i>
                </a>
              </div>
              <div style={{ margin: '10px' }}>
                <a href={rgpdPDF} target='_blank'>
                  <i>Política de privacidade</i>
                </a>
              </div>
            </div>
          </div>
            <div className='copyright' id='copyright'>
              © {new Date().getFullYear()}, Coded by{' '}
              <a href='https://closerdev.com' target='_blank'>
                Closerdev
              </a>
              .
            </div>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
